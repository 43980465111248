<template>
  <div class="home">
    <div class="flex container px-4  pl-14 flex-col items-center justify-center" v-html="content.content"></div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import VFooter from "@/views/components/Footer";
import Feed from "@/mixins/Feed";

export default {
  components: {VFooter},
  mixins: [Feed],
  data() {
    return {
      content: ""
    }
  },
  metaInfo:{
    title: 'Kullanım Şartları',
    meta: [
      { property: 'og:title', content:'Kullanım Şartları - Online Adam Asmaca Oyunu' },
      { property: 'description', content:"Adam Asmaca Oyunu senin gibi pek çok oyunseverin oynadığı ve birbiriyle yarıştığı eğlenceli bir oyun olarak, tüm oyuncularının birbirleri ile iyi geçinmesi ve oyunun kesintisiz olarak sizlere ulaştırabilmesi için burada belirtilen kullanım kıstaslarını belirlemiştir.Oyuna dahil olmadan önce bu kıstaslarımızı okuyup, onayladığınız tarafımızca kabul görülmektedir." },
    ]
  },
  methods: {
    ...mapActions(["getContent"])
  },
  created() {
    this.getContent(3).then((response) => {
      this.content = response;
      this.feedAllEvent('view_tos', null, 'engagement')
    })
  },
}
</script>